// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.99.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.99.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i_n_ey3_Itcw8_YMeQfd{width:24px;height:24px;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;justify-content:center;align-items:center}.i_n_ey3_Itcw8_YMeQfd i::before{color:inherit}`, "",{"version":3,"sources":["webpack://./src/components_v2/common/Icon/Icon.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CACA,sBAAA,CACA,kBAAA,CAGI,gCACI,aAAA","sourcesContent":[".outer-icon {\n    width: 24px;\n    height: 24px;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n\n    i {\n        &::before {\n            color: inherit;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer-icon": `i_n_ey3_Itcw8_YMeQfd`
};
export default ___CSS_LOADER_EXPORT___;
